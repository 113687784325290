import React, { useMemo } from "react";
import {
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  ListButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import { useGetCryptors } from "../../api/cryptor.api";

export const CryptorList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();

  const { data: cryptors } = useGetCryptors();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 50,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Nom",
        minWidth: 200,
      },
      {
        field: "image.url",
        headerName: "Image",
        minWidth: 50,
        valueGetter: (params) => params.row?.image?.url,
        renderCell: function render({ value }) {
          return <img src={value} alt="Cryptor" width="50" />;
        },
      },
      {
        field: "reward",
        headerName: "Récompense",
        type: "number",
        minWidth: 50,
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        minWidth: 120,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "quiz",
        headerName: "Quiz",
        sortable: false,
        width: 50,
        renderCell: function render({ row }) {
          return (
            <>
              <IconButton onClick={() => navigate(`/cryptors/${row.id}/quiz`)}>
                <QuizIcon />
              </IconButton>
            </>
          );
        },
      },
      {
        field: "chat",
        headerName: "Chat",
        sortable: false,
        width: 50,
        renderCell: function render({ row }) {
          return (
            <>
              <ListButton
                hideText
                onClick={() => {
                  navigate(`/cryptors/${row.id}/chat-message`);
                }}
              />
            </>
          );
        },
      },
      {
        field: "archived",
        headerName: "Archivé",
        type: "boolean",
        width: 70,
      },
      {
        field: "draft",
        headerName: "Publié",
        type: "boolean",
        width: 70,
        valueGetter: (params) => !params.row.draft,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton
                hideText
                recordItemId={row.id}
                confirmTitle="Etes-vous sûr de vouloir supprimer ce cryptor ? Cette action est définitive et entraînera la suppression de tous les points accumulés par les utilisateurs."
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 90,
      },
    ],
    [navigate]
  );

  return (
    <List>
      <DataGrid
        rows={cryptors}
        columns={columns}
        autoHeight
        initialState={{ filter: { filterModel: { items: [] } } }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
      />
    </List>
  );
};
