import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateCryptor, Cryptor, UpdateCryptor } from "shared";

const getCryptors = async () =>
  axios
    .get<Cryptor[]>(`/cryptors`)
    .then((res) => res.data);

export const useGetCryptors = () => {
  return useQuery({
    initialData: [],
    queryKey: ["cryptors"],
    queryFn: () => getCryptors(),
  });
};

const getCryptor = async (cryptorId: number) =>
  axios.get<Cryptor>(`/cryptors/${cryptorId}`).then((res) => res.data);

export const useGetCryptor = (cryptorId: number) => {
  return useQuery({
    queryKey: ["cryptors", cryptorId],
    queryFn: () => getCryptor(cryptorId),
  });
};

const postCryptor = async (data: CreateCryptor) =>
  axios.post(`/cryptors`, data).then((res) => res.data);

export const usePostCryptor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postCryptor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cryptors"] });
    },
  });
};

const patchCryptor = async ({
  id,
  ...data
}: {
  id: number;
} & UpdateCryptor) =>
  axios.patch(`/cryptors/${id}`, data).then((res) => res.data);

export const usePatchCryptor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchCryptor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cryptors"] });
    },
  });
};
