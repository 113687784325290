import { Autocomplete, TextField } from "@mui/material";
import { FC, useMemo } from "react";
import { Cryptor } from "../../../../../backend/shared/types/cryptors/cryptors.type";
import { useMany } from "@refinedev/core";
import { Control, Controller, FieldValues } from "react-hook-form";

interface UserCryptorDraftAccessSelectProps {
  control: Control<FieldValues>;

}

const UserCryptorDraftAccessSelect: FC<UserCryptorDraftAccessSelectProps> = ({
  control,
}) => {
  const { data } = useMany<Cryptor>({
    resource: "cryptors",
    ids: [],
  });

  const options = useMemo(
    () => data?.data?.map((el) => ({ id: el.id })) || [],
    [data]
  );

  return (
    <Controller
      control={control}
      name="cryptorsDraftAccess"
      render={({ field }) => {
        return (
          <Autocomplete
            multiple
            id="user-cryptor-draft-access-select"
            options={options}
            getOptionLabel={(option) =>
              data?.data?.find((el) => el.id === option.id)?.name || ""
            }
            value={field.value}
            onChange={(e, data) => field.onChange(data)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Acces aux cryptors en brouillon"
                placeholder="Cryptor"
              />
            )}
          />
        );
      }}
    />
  );
};

export default UserCryptorDraftAccessSelect;
