import React, { useMemo } from "react";
import { List } from "@refinedev/mui";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { IconButton } from "@mui/material";
import { useGetTranslations, useUpdateTranslation } from "../../api/translation.api";
import SaveIcon from "@mui/icons-material/Save";

export const TranslationList: React.FC<IResourceComponentsProps> = () => {

  const { data: translations } = useGetTranslations();
  const { mutateAsync: updateTranslation } = useUpdateTranslation();

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: "id", headerName: "Id", type: "number", minWidth: 50 },
      { field: "languageCode", headerName: "Langue", minWidth: 50 },
      {
        field: "value",
        headerName: "Valeur",
        minWidth: 200,
        flex: 1,
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 200,
        sortable: false,

        renderCell: (record) => {
          return (
            <IconButton
              onClick={() => {
                updateTranslation({
                  id: record.id as number,
                  value: record.row.value,
                });
              }}
            >
              <SaveIcon />
            </IconButton>
          );
        },
      },
    ],
    [updateTranslation]
  );

  return (
    <List title="Traduction">
      <DataGrid
        rows={translations}
        columns={columns}
        autoHeight
        initialState={{ filter: { filterModel: { items: [] } } }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
      />
    </List>
  );
};
