import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Faq } from "shared";

const getFaqs = async () =>
  axios.get<Faq[]>(`/faq`).then((res) => res.data);

export const useGetFaqs = () => {
  return useQuery({
    initialData: [],
    queryKey: ["faqs"],
    queryFn: () => getFaqs(),
  });
};