import { Create, Edit, SaveButtonProps, useAutocomplete } from "@refinedev/mui";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import {
  Controller,
  FieldValues,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { ArrowBack, Close } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import UploadButton from "../../../components/forms/UploadButton";
import { upload } from "../../../utils/upload";
import { CreateChatMessage } from "../../../../../backend/shared";
import { ChatProposition } from "../../../../../backend/shared/types/chat/chatProposition.type";
import ChatMessageChildrenSelector from "./ChatMessageChildrenSelector";
import QuestionReward from "./QuestionReward";
import { getTranslation } from "../../../utils/translation";
import {
  useGetChatMessage,
  usePostChatMessage,
  usePatchChatMessage,
} from "../../../api/chat-message.api";

interface ICreateEdit {
  type: "create" | "edit";
  isLoading: boolean;
  saveButtonProps: SaveButtonProps;
  children: React.ReactNode;
}

const CreateEdit: FC<ICreateEdit> = ({ type, children, ...rest }) => {
  const { cryptorId } = useParams();
  const { push } = useNavigation();
  if (type === "create") {
    return <Create {...rest}>{children}</Create>;
  } else if (type === "edit") {
    return (
      <Edit
        {...rest}
        goBack={
          <IconButton
            onClick={() => push(`/cryptors/${cryptorId}/chat-message`)}
          >
            <ArrowBack />
          </IconButton>
        }
      >
        {children}
      </Edit>
    );
  }
  return null;
};

interface IChatMessageCreateEdit extends IResourceComponentsProps {
  type: "create" | "edit";
}

export const ChatMessageCreateEdit: FC<IChatMessageCreateEdit> = ({ type }) => {
  const { cryptorId, id } = useParams();
  const { push } = useNavigation();
  const [questionQuizSelected, setQuestionQuizSelected] =
    useState<"question">();

  const { data, isFetched } = useGetChatMessage(
    Number(cryptorId),
    id as string
  );
  const { mutateAsync: postChatMessage } = usePostChatMessage(Number(cryptorId));
  const { mutateAsync: patchChatMessage } = usePatchChatMessage(Number(cryptorId), id as string);

  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    register,
    setError,
    control,
    formState: { isLoading: formLoading, isDirty, isValid, errors },
  } = useForm(); // TODO: setup zod validation

  const {
    append: questionPropositionsAppend,
    remove: questionPropositionsRemove,
  } = useFieldArray({
    control,
    name: "question.propositions",
    rules: {
      validate: (value) => {
        if (questionQuizSelected !== "question") {
          return true;
        }
        if (getValues("question")) {
          return value?.length > 0;
        }
      },
    },
  });

  useEffect(() => {
    if (!data) return;
    setValue("isEntryPoint", data.isEntryPoint);
    setValue("shouldTriggerQuiz", data.shouldTriggerQuiz);
    setValue("text", getTranslation(data?.textTranslation)?.value);
    setValue("video.url", data?.video?.url);
    setValue("image.url", data?.image?.url);
    setValue("audio.url", data?.audio?.url);
    setValue("chatMessageChildrenId", data?.chatMessageChildrenId);
    setValue(
      `question.goodAnswerDetails.description`,
      getTranslation(data?.question?.goodAnswerDetails?.descriptionTranslation)?.value
    );
    setValue(
      `question.badAnswerDetails.description`,
      getTranslation(data?.question?.badAnswerDetails?.descriptionTranslation)?.value
    )
    data?.question?.propositions.forEach((proposition, pIndex) => {
      setValue(
        `question.propositions[${pIndex}].text`,
        getTranslation(proposition.textTranslation)?.value
      );
      setValue(
        `question.propositions[${pIndex}].isCorrect`,
        proposition.isCorrect
      );
      setValue(
        `question.propositions[${pIndex}].chatMessageId`,
        proposition.chatMessageId
      );
    });
    setQuestionQuizSelected(
      (data?.question?.propositions?.length || 0) > 0 ? "question" : undefined
    );
  }, [data, isFetched, setValue]);

  useEffect(() => {
    if (questionQuizSelected === "question") {
      setValue(
        "question.text",
        getTranslation(data?.question?.textTranslation)?.value
      );
      data?.question?.propositions.forEach((proposition, pIndex) => {
        setValue(
          `question.propositions[${pIndex}].text`,
          getTranslation(proposition.textTranslation)?.value
        );
      });
    }
  }, [data, questionQuizSelected, setValue]);

  useEffect(() => {
    setQuestionQuizSelected(data?.question ? "question" : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuestionQuizSelected, formLoading]);

  const { autocompleteProps: chatMessageAutocompleteProps } = useAutocomplete({
    resource: `chat/cryptors/${cryptorId}/chat-message`,
    defaultValue: data?.chatMessageChildrenId || undefined,
  });

  const onFinishHandler = async (data: FieldValues) => {
    if (questionQuizSelected !== "question") data.question = undefined;

    const questionBase = (question: FieldValues) => {
      if (question) {
        return {
          id: question.id ? question.id : undefined,
          textTranslation: { value: question.text },
          type: question.type,
          reward: question.reward,
          goodAnswerDetails: answerDetail(question.goodAnswerDetails),
          badAnswerDetails: answerDetail(question.badAnswerDetails),
        };
      }
      return undefined;
    };

    const propositionBase = (proposition: FieldValues) => {
      if (proposition.text) {
        return {
          id: proposition.id,
          isCorrect: proposition.isCorrect,
          textTranslation: { value: proposition.text },
        };
      }
      return null;
    };

    const answerDetail = (answerDetail: FieldValues) => {
      if (answerDetail?.description) {
        return {
          id: answerDetail.id,
          descriptionTranslation: { value: answerDetail.description },
        };
      }
      return null;
    };

    const body = {
      isEntryPoint: data.isEntryPoint ? true : false,
      shouldTriggerQuiz: data.shouldTriggerQuiz ? true : false,
      textTranslation: { value: data.text },
      image: data?.image?.url ? { url: data.image.url } : null,
      video: data?.video?.url ? { url: data.video.url } : null,
      audio: data?.audio?.url ? { url: data.audio.url } : null,
      question: data.question
        ? {
          ...questionBase(data.question),
          propositions: data.question.propositions?.map(
            (proposition: FieldValues) => ({
              ...propositionBase(proposition),
              chatMessageId: proposition.chatMessageId || null,
            })
          ),
        }
        : undefined,
      quiz: data.quiz
        ? {
          id: data.quiz.id ? data.quiz.id : undefined,
          reward: data.quiz.reward,
          timerQuestion: data.quiz.timerQuestion,
          chatMessageChildren80PlusId:
            data.quiz.chatMessageChildren80PlusId || null,
          chatMessageChildren50PlusId:
            data.quiz.chatMessageChildren50PlusId || null,
          chatMessageChildren50LessId:
            data.quiz.chatMessageChildren50LessId || null,
          questions: data.quiz.questions.map((question: FieldValues) => ({
            ...questionBase(question),
            propositions: question.propositions?.map(
              (proposition: FieldValues) => propositionBase(proposition)
            ),
          })),
        }
        : undefined,
      chatMessageChildrenId: data.chatMessageChildrenId || undefined,
    } as CreateChatMessage;

    if (type === "create") {
      postChatMessage(body, {
        onSuccess: (response) => {
          push(`/cryptors/${cryptorId}/chat-message/edit/${response.id}`);
        },
      });
    } else if (type === "edit") {
      patchChatMessage(body);
    }
  };

  return (
    <CreateEdit
      type={type}
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit((data) =>
          onFinishHandler(data as CreateChatMessage)
        ),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="isEntryPoint"
          defaultValue={false}
          render={({ field }) => {
            return (
              <FormControlLabel
                checked={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                control={<Checkbox />}
                label="Est le point d'entrée"
                defaultValue={field.value}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="shouldTriggerQuiz"
          defaultValue={false}
          render={({ field }) => {
            return (
              <FormControlLabel
                checked={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                control={<Checkbox />}
                label="Déclenchement du quiz"
                defaultValue={field.value}
              />
            );
          }}
        />

        <TextField
          {...register("text")}
          defaultValue={null}
          error={!!(errors as any)?.text}
          helperText={(errors as any)?.text?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Texte"
        />
        <TextField
          {...register("video.url", {
            validate: (value) => {
              if (!value) return true;
              try {
                const url = new URL(value);
                if (
                  url.host !== "www.youtube.com" ||
                  !url.searchParams.get("v")
                ) {
                  return false;
                }
                return true;
              } catch (error) {
                return false;
              }
            },
          })}
          defaultValue={null}
          error={!!(errors as any)?.text}
          helperText={(errors as any)?.text?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Video URL Youtube"
        />
        <Controller
          control={control}
          name="image.url"
          defaultValue={null}
          render={({ field }) => (
            <UploadButton
              id="image"
              inputSrc={field.value}
              label="Image"
              labelWidth={50}
              onError={() =>
                setError("image.url", {
                  message: "L'upload a échoué. Veuillez réessayer",
                })
              }
              onChange={async (event) => {
                const url = await upload(event, "chatAssets/image");
                field.onChange(url);
              }}
              onDelete={() => field.onChange(null)}
              errorMessage={(errors as any)?.image?.url?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="audio.url"
          defaultValue={null}
          render={({ field }) => (
            <UploadButton
              id="audio"
              inputSrc={field.value}
              label="Son"
              labelWidth={50}
              type="audio"
              onError={() =>
                setError("audio.url", {
                  message: "L'upload a échoué. Veuillez réessayer",
                })
              }
              onChange={async (event) => {
                const url = await upload(event, "chatAssets/audio");
                field.onChange(url);
              }}
              onDelete={() => field.onChange(null)}
              errorMessage={(errors as any)?.audio?.url?.message}
            />
          )}
        />
        <ChatMessageChildrenSelector
          control={control}
          name="chatMessageChildrenId"
          autocompleteProps={chatMessageAutocompleteProps}
        />
        <Divider sx={{ margin: 4 }}>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            width={500}
            gap={2}
          >
            <Button
              fullWidth
              variant={
                questionQuizSelected === "question" ? "contained" : "outlined"
              }
              color="primary"
              onClick={() => {
                if (questionQuizSelected === "question") {
                  setQuestionQuizSelected(undefined);
                } else {
                  setQuestionQuizSelected("question");
                }
              }}
            >
              Question
            </Button>
          </Stack>
        </Divider>
        {questionQuizSelected === "question" && (
          <>
            <Controller
              control={control}
              name="question.type"
              defaultValue="CHOICE"
              render={({ field }) => (
                <FormControl margin={"normal"}>
                  <InputLabel htmlFor="outlined-adornment">Type</InputLabel>
                  <Select {...field} label="Ville">
                    {[
                      { label: "Choix", value: "CHOICE" },
                      { label: "Question", value: "QUESTION" },
                    ].map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <QuestionReward
              control={control}
              errors={errors}
              textName="question.text"
              rewardName="question.reward"
            />
            {(watch("question.propositions") as ChatProposition[])?.map(
              (proposition, index) => {
                return (
                  <Stack
                    key={"question.propositions" + proposition.id}
                    direction="row"
                    alignItems="center"
                    gap={3}
                  >
                    <TextField
                      {...register(`question.propositions[${index}].text`)}
                      defaultValue={null}
                      error={!!(errors as any)?.text}
                      helperText={(errors as any)?.text?.message}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label={`Réponse ${index + 1}`}
                    />
                    <Controller
                      control={control}
                      name={`question.propositions[${index}].isCorrect`}
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            checked={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            control={<Checkbox />}
                            label="Est correct"
                            sx={{ minWidth: 125 }}
                          />
                        );
                      }}
                    />
                    <ChatMessageChildrenSelector
                      control={control}
                      name={`question.propositions[${index}].chatMessageId`}
                      autocompleteProps={chatMessageAutocompleteProps}
                    />
                    <IconButton
                      onClick={() => questionPropositionsRemove(index)}
                    >
                      <Close />
                    </IconButton>
                  </Stack>
                );
              }
            )}
            <Button
              onClick={() =>
                questionPropositionsAppend({ text: "", isCorrect: false })
              }
            >
              Ajouter une proposition
            </Button>
            <TextField
              {...register(`question.goodAnswerDetails.description`)}
              defaultValue={null}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Détails de la bonne réponse"
            />
            <TextField
              {...register(`question.badAnswerDetails.description`)}
              defaultValue={null}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Détails de la mauvaise réponse"
            />
          </>
        )}
      </Box>
    </CreateEdit>
  );
};
