import { Create } from "@refinedev/mui";
import { Box } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { FormProvider } from "react-hook-form";
import CryptorNameTextField from "./components/CryptorNameTextField";
import CryptorBirthDateField from "./components/CryptorBirthDateField";
import CryptorDeathDateField from "./components/CryptorDeathDateField";
import CryptorPlaceOfBirthField from "./components/CryptorPlaceOfBirthField";
import CryptorTitleField from "./components/CryptorTitleField";
import CryptorQuoteField from "./components/CryptorQuoteField";
import CryptorDescriptionField from "./components/CryptorDescriptionField";
import CryptorRewardField from "./components/CryptorRewardField";
import CryptorPrimaryColorField from "./components/CryptorPrimaryColorField";
import SecondaryColorField from "./components/SecondaryColorField";
import UploadField from "../../components/forms/UploadField";
import { removeQueryParams } from "../../utils/parseUrl";
import CryptorAddressesField from "./components/CryptorAddressesField";
import CryptorLinkWithThePlaceField from "./components/CryptorLinkWithThePlaceField";
import CryptorArtefactField from "./components/CryptorArtefactField";
import { useEffect } from "react";
import CryptorDifficultyField from "./components/CryptorDifficultyField";
import { zodResolver } from "@hookform/resolvers/zod";
import { CryptorFormData, CryptorSchema } from "./utils/cryptor.schema";
import { Cryptor } from "shared";
import { usePostCryptor } from "../../api/cryptor.api";
import { useNavigate } from "react-router-dom";
import CryptorMistralAgentIdField from "./components/CryptorMistralAgentIdField";
import CryptorShouldAnimateEyesField from "./components/CryptorShouldAnimateEyesField";

export const CryptorCreate: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { mutateAsync: postCryptor } = usePostCryptor();

  const methods = useForm<Cryptor, any, CryptorFormData>({
    resolver: zodResolver(CryptorSchema),
  });

  const {
    register,
    control,
    formState: { errors, isValid, isDirty },
    setError,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset({
      addresses: [],
      artefactDescription: { isActive: false },
      artefactLinkWithThePlace: { isActive: false },
      artefactQuote: { isActive: false },
      draft: true,
      archived: false,
    });
  }, [reset]);

  const onFinishHandler = async (data: CryptorFormData) => {
    await postCryptor({
      name: data.name,
      reward: data.reward,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
      image: { url: removeQueryParams(data.image.url) },
      obfuscatedImage: { url: removeQueryParams(data.obfuscatedImage.url) },
      avatar: { image: { url: removeQueryParams(data.avatar.image.url) } },
      characterImage: { url: removeQueryParams(data.characterImage.url) },
      addresses: data.addresses.map((address) => ({
        locationAddress: address.locationAddress,
        cityId: address.cityId,
        location: {
          latitude: Number(address.location?.latitude),
          longitude: Number(address.location?.longitude),
        },
        locationRadius: Number(address.locationRadius),
      })),
      birthDate: data.birthDate as unknown as Date,
      deathDate: data.deathDate as unknown as Date,
      placeOfBirth: data.placeOfBirth,
      translationTitle: { value: data.title },
      translationLinkWithThePlace: { value: data.linkWithThePlace },
      translationDescription: { value: data.description },
      translationQuote: { value: data.quote },
      translationDifficulty: { value: data.difficulty },
      artefactLinkWithThePlace: data.artefactLinkWithThePlace.isActive
        ? {
          image: { url: data.artefactLinkWithThePlace.image.url },
          titlePopUpTranslation: {
            value: data.artefactLinkWithThePlace.titlePopUp,
          },
          textPopUpTranslation: {
            value: data.artefactLinkWithThePlace.textPopUp,
          },
          reward: data.artefactLinkWithThePlace.reward,
        }
        : null,
      artefactDescription: data.artefactDescription.isActive
        ? {
          image: { url: data.artefactDescription.image.url },
          titlePopUpTranslation: {
            value: data.artefactDescription.titlePopUp,
          },
          textPopUpTranslation: { value: data.artefactDescription.textPopUp },
          reward: data.artefactDescription.reward,
        }
        : null,
      artefactQuote: data.artefactQuote.isActive
        ? {
          image: { url: data.artefactQuote.image.url },
          titlePopUpTranslation: {
            value: data.artefactQuote.titlePopUp,
          },
          textPopUpTranslation: { value: data.artefactQuote.textPopUp },
          reward: data.artefactQuote.reward,
        }
        : null,
      mistralAIAgentIdFR: data.mistralAIAgentIdFR || null,
      mistralAIAgentIdEN: data.mistralAIAgentIdEN || null,
      mistralAIAgentIdES: data.mistralAIAgentIdES || null,
      shouldAnimateEyes: !!data.shouldAnimateEyes,
    });
    navigate("/cryptors");
  };

  return (
    <Create
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <CryptorNameTextField register={register} />
          <Box display="flex" flexDirection="row" gap={2}>
            <CryptorBirthDateField control={control} />
            <CryptorDeathDateField control={control} />
            <CryptorPlaceOfBirthField register={register} />
          </Box>
          <CryptorTitleField register={register} />
          <CryptorLinkWithThePlaceField register={register} />
          <CryptorQuoteField register={register} />
          <CryptorDescriptionField register={register} />
          <CryptorDifficultyField register={register} />
          <CryptorRewardField register={register} />
          <CryptorPrimaryColorField register={register} />
          <SecondaryColorField register={register} />

          <CryptorArtefactField
            artefactName="artefactLinkWithThePlace"
            title="Artefact (lien avec le lieu)"
          />
          <CryptorArtefactField
            artefactName="artefactDescription"
            title="Artefact (description)"
          />
          <CryptorArtefactField
            artefactName="artefactQuote"
            title="Artefact (citation)"
          />
          <CryptorAddressesField />
          <UploadField
            name="image.url"
            uploadButtonLabel="Upload image"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.image?.url?.message}
          />
          <UploadField
            name="obfuscatedImage.url"
            uploadButtonLabel="Upload image (dissimulée)"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.obfuscatedImage?.url?.message}
          />
          <UploadField
            name="avatar.image.url"
            uploadButtonLabel="Upload avatar"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.avatar?.image?.url?.message}
          />
          <UploadField
            name="characterImage.url"
            uploadButtonLabel="Upload image personnage"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.avatar?.image?.url?.message}
          />
          <CryptorMistralAgentIdField register={register} registerKey="mistralAIAgentIdFR" label="Mistral Agent ID FR" />
          <CryptorMistralAgentIdField register={register} registerKey="mistralAIAgentIdEN" label="Mistral Agent ID EN" />
          <CryptorMistralAgentIdField register={register} registerKey="mistralAIAgentIdES" label="Mistral Agent ID ES" />
          <CryptorShouldAnimateEyesField control={control} />
        </Box>
      </FormProvider>
    </Create>
  );
};
