import { Edit } from "@refinedev/mui";
import { Box, Divider, TextField } from "@mui/material";
import { IResourceComponentsProps } from "@refinedev/core";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo } from "react";
import CryptorNameTextField from "./components/CryptorNameTextField";
import CryptorBirthDateField from "./components/CryptorBirthDateField";
import CryptorDeathDateField from "./components/CryptorDeathDateField";
import CryptorPlaceOfBirthField from "./components/CryptorPlaceOfBirthField";
import CryptorTitleField from "./components/CryptorTitleField";
import CryptorQuoteField from "./components/CryptorQuoteField";
import CryptorDescriptionField from "./components/CryptorDescriptionField";
import CryptorRewardField from "./components/CryptorRewardField";
import CryptorPrimaryColorField from "./components/CryptorPrimaryColorField";
import SecondaryColorField from "./components/SecondaryColorField";
import UploadField from "../../components/forms/UploadField";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import CryptorAddressesField from "./components/CryptorAddressesField";
import { getTranslation } from "../../utils/translation";
import CryptorLinkWithThePlaceField from "./components/CryptorLinkWithThePlaceField";
import CryptorArtefactField from "./components/CryptorArtefactField";
import { useNavigate, useParams } from "react-router-dom";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CryptorDifficultyField from "./components/CryptorDifficultyField";
import { zodResolver } from "@hookform/resolvers/zod";
import { CryptorFormData, CryptorSchema } from "./utils/cryptor.schema";
import { useGetCryptor, usePatchCryptor } from "../../api/cryptor.api";
import { removeQueryParams } from "../../utils/parseUrl";
import CryptorDescriptionAIField from "./components/CryptorDescriptionAI";
import CryptorMistralAgentIdField from "./components/CryptorMistralAgentIdField";
import CryptorShouldAnimateEyesField from "./components/CryptorShouldAnimateEyesField";

export const CryptorEdit: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const methods = useForm<CryptorFormData, any, CryptorFormData>({
    resolver: zodResolver(CryptorSchema),
  });

  const { id } = useParams() as unknown as { id: number };
  const { data: cryptor } = useGetCryptor(id);
  const { mutateAsync: patchCryptor } = usePatchCryptor();

  const {
    register,
    formState: { errors, isValid },
    setError,
    control,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if (!cryptor || !reset) return;
    reset({
      title: getTranslation(cryptor.titleTranslation)?.value || "",
      linkWithThePlace:
        getTranslation(cryptor.linkWithThePlaceTranslation)?.value || "",
      description: getTranslation(cryptor.descriptionTranslation)?.value || "",
      quote: getTranslation(cryptor.quoteTranslation)?.value || "",
      difficulty: getTranslation(cryptor.difficultyTranslation)?.value || "",
      addresses: cryptor.addresses.map((address) => ({
        cityId: address.address.city.id,
        locationAddress: address.address.locationAddress,
        locationRadius: address.address.locationRadius,
        location: {
          latitude: address.address.location.latitude,
          longitude: address.address.location.longitude,
        },
        id: address.address.id,
      })),
      artefactLinkWithThePlace: cryptor.artefactLinkWithThePlace
        ? {
          id: cryptor.artefactLinkWithThePlace.id,
          chatMessageId: Number(
            cryptor.artefactLinkWithThePlace.chatMessageId
          ),
          image: { url: cryptor.artefactLinkWithThePlace.image.url },
          titlePopUp:
            getTranslation(
              cryptor.artefactLinkWithThePlace.titlePopUpTranslation
            )?.value || "",
          textPopUp:
            getTranslation(
              cryptor.artefactLinkWithThePlace.textPopUpTranslation
            )?.value || "",
          reward: Number(cryptor.artefactLinkWithThePlace.reward),
          isActive: true,
        }
        : { isActive: false },
      artefactDescription: cryptor.artefactDescription
        ? {
          id: cryptor.artefactDescription.id,
          chatMessageId: Number(cryptor.artefactDescription.chatMessageId),
          image: { url: cryptor.artefactDescription.image.url },
          titlePopUp:
            getTranslation(cryptor.artefactDescription.titlePopUpTranslation)
              ?.value || "",
          textPopUp:
            getTranslation(cryptor.artefactDescription.textPopUpTranslation)
              ?.value || "",
          reward: Number(cryptor.artefactDescription.reward),
          isActive: true,
        }
        : { isActive: false },
      artefactQuote: cryptor.artefactQuote
        ? {
          id: cryptor.artefactQuote.id,
          chatMessageId: Number(cryptor.artefactQuote.chatMessageId),
          image: { url: cryptor.artefactQuote.image.url },
          titlePopUp:
            getTranslation(cryptor.artefactQuote.titlePopUpTranslation)
              ?.value || "",
          textPopUp:
            getTranslation(cryptor.artefactQuote.textPopUpTranslation)
              ?.value || "",
          reward: Number(cryptor.artefactQuote.reward),
          isActive: true,
        }
        : { isActive: false },
      archived: cryptor.archived,
      draft: cryptor.draft,
      descriptionForAI: cryptor.descriptionForAI || undefined,
      birthDate: cryptor.birthDate ? new Date(cryptor.birthDate) : null,
      deathDate: cryptor.deathDate ? new Date(cryptor.deathDate) : null,
      placeOfBirth: cryptor.placeOfBirth,
      avatar: { image: { url: cryptor.avatar.image.url } },
      characterImage: { url: cryptor.characterImage.url },
      primaryColor: cryptor.primaryColor,
      secondaryColor: cryptor.secondaryColor,
      image: { url: cryptor.image.url },
      obfuscatedImage: { url: cryptor.obfuscatedImage.url },
      name: cryptor.name,
      reward: cryptor.reward,
      mistralAIAgentIdFR: cryptor.mistralAIAgentIdFR || undefined,
      mistralAIAgentIdEN: cryptor.mistralAIAgentIdEN || undefined,
      mistralAIAgentIdES: cryptor.mistralAIAgentIdES || undefined,
      shouldAnimateEyes: cryptor.shouldAnimateEyes,
    });
  }, [reset, cryptor]);

  const onFinishHandler = async (data: CryptorFormData) => {
    await patchCryptor({
      id,
      name: data.name,
      reward: data.reward,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
      image: { url: removeQueryParams(data.image.url) },
      obfuscatedImage: { url: removeQueryParams(data.obfuscatedImage.url) },
      avatar: { image: { url: removeQueryParams(data.avatar.image.url) } },
      characterImage: { url: removeQueryParams(data.characterImage.url) },
      addresses: data.addresses.map((address) => ({
        locationAddress: address.locationAddress,
        cityId: address.cityId,
        location: {
          latitude: Number(address.location?.latitude),
          longitude: Number(address.location?.longitude),
        },
        locationRadius: Number(address.locationRadius),
      })),
      descriptionForAI: data.descriptionForAI,
      birthDate: data.birthDate as unknown as Date,
      deathDate: data.deathDate as unknown as Date,
      placeOfBirth: data.placeOfBirth,
      translationTitle: { value: data.title },
      translationLinkWithThePlace: { value: data.linkWithThePlace },
      translationDescription: { value: data.description },
      translationQuote: { value: data.quote },
      translationDifficulty: { value: data.difficulty },
      artefactLinkWithThePlace: data.artefactLinkWithThePlace.isActive
        ? {
          id: data.artefactLinkWithThePlace.id,
          image: { url: data.artefactLinkWithThePlace.image.url },
          titlePopUpTranslation: {
            value: data.artefactLinkWithThePlace.titlePopUp,
          },
          textPopUpTranslation: {
            value: data.artefactLinkWithThePlace.textPopUp,
          },
          reward: data.artefactLinkWithThePlace.reward,
          chatMessageId: Number(data.artefactLinkWithThePlace.chatMessageId),
        }
        : null,
      artefactDescription: data.artefactDescription.isActive
        ? {
          id: data.artefactDescription.id,
          image: { url: data.artefactDescription.image.url },
          titlePopUpTranslation: {
            value: data.artefactDescription.titlePopUp,
          },
          textPopUpTranslation: { value: data.artefactDescription.textPopUp },
          reward: data.artefactDescription.reward,
          chatMessageId: Number(data.artefactDescription.chatMessageId),
        }
        : null,
      artefactQuote: data.artefactQuote.isActive
        ? {
          id: data.artefactQuote.id,
          image: { url: data.artefactQuote.image.url },
          titlePopUpTranslation: {
            value: data.artefactQuote.titlePopUp,
          },
          textPopUpTranslation: { value: data.artefactQuote.textPopUp },
          reward: data.artefactQuote.reward,
          chatMessageId: Number(data.artefactQuote.chatMessageId),
        }
        : null,
      mistralAIAgentIdFR: data.mistralAIAgentIdFR || null,
      mistralAIAgentIdEN: data.mistralAIAgentIdEN || null,
      mistralAIAgentIdES: data.mistralAIAgentIdES || null,
      shouldAnimateEyes: !!data.shouldAnimateEyes,
    });

    navigate("/cryptors");
  };

  const isArchived = useMemo(() => cryptor?.archived, [cryptor]);

  const isDraft = useMemo(() => cryptor?.draft, [cryptor]);

  const onArchiveHandler = (archived: boolean) => {
    patchCryptor({ id, archived });
  };

  const onDraftHandler = (draft: boolean) => {
    patchCryptor({ id, draft });
  };

  return (
    <Edit
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isValid,
      }}
      headerButtons={[
        <LoadingButton
          onClick={() => navigate(`/questions/edit?cryptorId=${id}`)}
        >
          <Box display="flex" gap={0.5}>
            <PersonSearchIcon />
            Questions post capture
          </Box>
        </LoadingButton>,
        <LoadingButton
          onClick={() => {
            navigate(`/hints/edit?cryptorId=${id}`);
            // It reloads the page and prevents the page to not rerender properly. A fix is needed.
            navigate(0);
          }}
        >
          <Box display="flex" gap={0.5}>
            <PersonSearchIcon />
            Indices
          </Box>
        </LoadingButton>,
        <Divider orientation="vertical" flexItem />,
        <LoadingButton
          onClick={handleSubmit(() => onArchiveHandler(!isArchived))}
          startIcon={isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
        >
          {isArchived ? "Désarchiver" : "Archiver"}
        </LoadingButton>,
        <LoadingButton
          onClick={handleSubmit(() => onDraftHandler(!isDraft))}
          startIcon={isDraft ? <PublicIcon /> : <PublicOffIcon />}
        >
          {isDraft ? "publier" : "dépublier"}
        </LoadingButton>,
      ]}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <TextField
            {...register("id", {
              required: "Ce champ est requis",
              valueAsNumber: true,
            })}
            defaultValue={null}
            error={!!(errors as any)?.id}
            helperText={(errors as any)?.id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label="Id"
            name="id"
            disabled
          />
          <CryptorNameTextField register={register} />
          <CryptorBirthDateField control={control} />
          <CryptorDeathDateField control={control} />
          <CryptorPlaceOfBirthField register={register} />
          <CryptorTitleField register={register} />
          <CryptorLinkWithThePlaceField register={register} />
          <CryptorQuoteField register={register} />
          <CryptorDescriptionField register={register} />
          <CryptorDifficultyField register={register} />
          <CryptorRewardField register={register} />
          <CryptorPrimaryColorField register={register} />
          <SecondaryColorField register={register} />

          <CryptorArtefactField
            cryptorId={cryptor?.id}
            artefactName="artefactLinkWithThePlace"
            title="Artefact (lien avec le lieu)"
          />
          <CryptorArtefactField
            cryptorId={cryptor?.id}
            artefactName="artefactDescription"
            title="Artefact (description)"
          />
          <CryptorArtefactField
            cryptorId={cryptor?.id}
            artefactName="artefactQuote"
            title="Artefact (citation)"
          />

          <CryptorAddressesField />
          <UploadField
            name="image.url"
            uploadButtonLabel="Upload image"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.image?.url?.message}
          />
          <UploadField
            name="obfuscatedImage.url"
            uploadButtonLabel="Upload image (dissimulée)"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.obfuscatedImage?.url?.message}
          />
          <UploadField
            name="avatar.image.url"
            uploadButtonLabel="Upload avatar"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.avatar?.image?.url?.message}
          />
          <UploadField
            name="characterImage.url"
            uploadButtonLabel="Upload image personnage"
            s3FoldersType={"cryptors"}
            control={control as any}
            setError={setError as any}
            errorMessage={(errors as any)?.avatar?.image?.url?.message}
          />
          <CryptorDescriptionAIField register={register} />
          <CryptorMistralAgentIdField register={register} registerKey="mistralAIAgentIdFR" label="Mistral Agent ID FR" />
          <CryptorMistralAgentIdField register={register} registerKey="mistralAIAgentIdEN" label="Mistral Agent ID EN" />
          <CryptorMistralAgentIdField register={register} registerKey="mistralAIAgentIdES" label="Mistral Agent ID ES" />
          <CryptorShouldAnimateEyesField control={control} />
        </Box>
      </FormProvider>
    </Edit>
  );
};