import React, { useMemo } from "react";
import {
  List,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { getTranslation } from "../../utils/translation";
import { useGetLevels } from "../../api/level.api";

export const LevelList: React.FC<IResourceComponentsProps> = () => {
  const { data: levels } = useGetLevels();

  const formatedData = levels.map((level) => ({
    ...level,
    name: getTranslation(level.nameTranslation)?.value,
  }));

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "level",
        headerName: "Niveau",
        type: "number",
        minWidth: 50,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Nom",
        minWidth: 200,
      },
      {
        field: "pointsRequired",
        flex: 1,
        headerName: "Points requis",
        minWidth: 200,
      },
      {
        field: "image.url",
        headerName: "Image",
        minWidth: 50,
        valueGetter: (params) => params.row?.image?.url,
        renderCell: function render({ value }) {
          return <img src={value} alt="Badge" width="50" />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.level} />
              <ShowButton hideText recordItemId={row.level} />
              <DeleteButton hideText recordItemId={row.level} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        rows={formatedData}
        columns={columns}
        autoHeight
        getRowId={(row) => row.level}
      />
    </List>
  );
};
