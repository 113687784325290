import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { User } from "shared";

const getUsers = async () =>
    axios.get<User[]>("/users").then((res) => res.data);

export const useGetUsers = () =>
    useQuery({
        initialData: [],
        queryKey: ["users"],
        queryFn: () => getUsers(),
    });