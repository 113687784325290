import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Advertisement } from "shared";

const getAdvertisements = async () =>
  axios.get<Advertisement[]>(`/advertisements`).then((res) => res.data);

export const useGetAdvertisements = () => {
  return useQuery({
    initialData: [],
    queryKey: ["advertisements"],
    queryFn: () => getAdvertisements(),
  });
};