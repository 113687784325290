import { TextField } from "@mui/material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorMistralAgentIdFieldProps {
  register: UseFormRegister<CryptorFormData>;
  registerKey: "mistralAIAgentIdFR" | "mistralAIAgentIdEN" | "mistralAIAgentIdES";
  label: string;
}

const CryptorMistralAgentIdField: FC<CryptorMistralAgentIdFieldProps> = ({
  register,
  registerKey,
  label
}) => {
  return (
    <TextField
      {...register(registerKey)}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label={label}
      name={registerKey}
    />
  );
};

export default CryptorMistralAgentIdField;
