import { z } from "zod";

const artefactSchema = z
  .object({
    isActive: z.literal(false),
  })
  .or(
    z.object({
      id: z.number().optional(),
      chatMessageId: z.number().min(1),
      image: z.object({ url: z.string().min(1) }),
      titlePopUp: z.string().min(1),
      textPopUp: z.string().min(1),
      reward: z.coerce.number().min(0),
      isActive: z.literal(true),
    })
  );

export const CryptorSchema = z.object({
  id: z.any().optional(),
  name: z.string().min(1),
  reward: z.number().min(0),
  image: z.object({ url: z.string().min(1) }),
  obfuscatedImage: z.object({ url: z.string().min(1) }),
  avatar: z.object({ image: z.object({ url: z.string().min(1) }) }),
  characterImage: z.object({ url: z.string().min(1) }),
  primaryColor: z.string().min(1),
  secondaryColor: z.string().min(1),
  addresses: z.array(
    z.object({
      locationAddress: z.string().min(1),
      cityId: z.number().min(1),
      location: z.object({
        latitude: z.coerce.number().min(-90).max(90),
        longitude: z.coerce.number().min(-180).max(180),
      }),
      locationRadius: z.coerce.number().min(0).nullable(),
    })
  ),
  birthDate: z.date().or(z.string().min(1)).nullish(),
  deathDate: z.date().or(z.string().min(1)).nullish(),
  placeOfBirth: z.string().nullable(),
  title: z.string().optional(),
  linkWithThePlace: z.string().optional(),
  description: z.string().optional(),
  quote: z.string().optional(),
  difficulty: z.string().optional(),
  artefactLinkWithThePlace: artefactSchema,
  artefactDescription: artefactSchema,
  artefactQuote: artefactSchema,
  archived: z.boolean(),
  draft: z.boolean(),
  descriptionForAI: z.string().optional(),
  mistralAIAgentIdFR: z.string().optional(),
  mistralAIAgentIdEN: z.string().optional(),
  mistralAIAgentIdES: z.string().optional(),
  shouldAnimateEyes: z.boolean().optional(),
});

export type CryptorFormData = z.infer<typeof CryptorSchema>;
