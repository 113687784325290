import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Level } from "shared";

const getLevels = async () =>
  axios.get<Level[]>(`/levels`).then((res) => res.data);

export const useGetLevels = () => {
  return useQuery({
    initialData: [],
    queryKey: ["levels"],
    queryFn: () => getLevels(),
  });
};