import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ChatMessage, CreateChatMessage, UpdateChatMessage } from "shared";

const getChatMessage = async (cryptorId: number, id: string) =>
  axios.get<ChatMessage>(`/chat/cryptors/${cryptorId}/chat-message/${id}`).then((res) => res.data);

export const useGetChatMessage = (cryptorId: number, id: string) =>
  useQuery({
    initialData: null,
    queryKey: ["cryptors", cryptorId, "chat-message", id],
    queryFn: () => getChatMessage(cryptorId, id),
  });


const postChatMessage = async (cryptorId: number, data: CreateChatMessage) =>
  axios.post(`/chat/cryptors/${cryptorId}/chat-message`, data).then((res) => res.data);

export const usePostChatMessage = (cryptorId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateChatMessage) => postChatMessage(cryptorId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["cryptors", cryptorId, "chat-message"]);
    },
  });
}


const patchChatMessage = async (cryptorId: number, id: string, data: UpdateChatMessage) =>
  axios.patch(`/chat/cryptors/${cryptorId}/chat-message/${id}`, data).then((res) => res.data);

export const usePatchChatMessage = (cryptorId: number, id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateChatMessage) => patchChatMessage(cryptorId, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["cryptors", cryptorId, "chat-message"]);
    },
  });
}
