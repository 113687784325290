import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ChatMessage, CreateChatMessage, UpdateChatMessage } from "shared";

const getChatMessages = async (cryptorId: number) => {
  return axios
    .get<ChatMessage[]>(`/chat/cryptors/${cryptorId}/chat-message/`)
    .then((response) => response.data);
};

export const useGetChatMessages = (cryptorId: number) => useQuery({
  initialData: [],
  queryKey: ["chatMessages", cryptorId],
  queryFn: () => getChatMessages(cryptorId),
});

const getChatMessage = async (cryptorId: number, messageId: number) => {
  return await axios.get<ChatMessage>(
    `/chat/cryptors/${cryptorId}/chat-message/${messageId}`
  ).then((response) => response.data);
};

export const useGetChatMessage = (cryptorId: number, messageId: number) => {
  return useQuery(["chatMessage", messageId], () =>
    getChatMessage(cryptorId, messageId)
  );
};

const postChatMessage = async (
  cryptorId: number,
  chatMessage: CreateChatMessage
) => {
  return axios.post<ChatMessage>(
    `/chat/cryptors/${cryptorId}/chat-message`,
    chatMessage
  ).then((response) => response.data);
};

export const usePostChatMessage = (cryptorId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (chatMessage: CreateChatMessage) =>
      postChatMessage(cryptorId, chatMessage),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hints"] });
    },
  });
};

const patchChatMessage = async (
  cryptorId: number,
  messageId: number,
  message: UpdateChatMessage
) => {
  return axios.patch<ChatMessage>(
    `/chat/cryptors/${cryptorId}/chat-message/${messageId}`,
    message
  ).then((response) => response.data);
};

export const usePatchChatMessage = (cryptorId: number, messageId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (message: UpdateChatMessage) =>
      patchChatMessage(cryptorId, messageId, message),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hints"] });
    },
  });
};
