import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";
import { Checkbox, FormControlLabel } from "@mui/material";

interface CryptorShouldAnimateEyesFieldProps {
  control: Control<CryptorFormData, {}>;
}

const CryptorShouldAnimateEyesField: FC<CryptorShouldAnimateEyesFieldProps> = ({ control }) => {
  return (
    <Controller
      control={control}
      name={`shouldAnimateEyes`}
      render={({ field }) => {
        return (
          <FormControlLabel
            checked={field.value || false}
            onChange={field.onChange}
            onBlur={field.onBlur}
            control={<Checkbox />}
            label="Animer les yeux"
            sx={{ minWidth: 125 }}
          />
        );
      }}
    />
  );
};

export default CryptorShouldAnimateEyesField;
