import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
  DateField,
  BooleanField,
} from "@refinedev/mui";
import { Typography, Stack, Box, Divider } from "@mui/material";
import { Cryptor } from "../../../../backend/shared/types/cryptors/cryptors.type";
import CryptorAddresses from "./components/CryptorAddresses";
import { getTranslation } from "../../utils/translation";
import SelectLanguage from "../../components/miscellaneous/SelectLanguage";
import i18n from "../../i18n";
import CryptorArtefactShow from "./components/CryptorArtefactShow";
import { LoadingButton } from "@mui/lab";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useNavigate } from "react-router-dom";

export const CryptorShow: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const {
    queryResult: { data, isLoading },
  } = useShow();

  const record = data?.data as Cryptor;
  const cryptorId = data?.data.id;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <Box display="flex" alignItems="center" gap={1}>
          <LoadingButton
            onClick={() => navigate(`/questions/show?cryptorId=${cryptorId}`)}
          >
            <Box display="flex" gap={0.5}>
              <PersonSearchIcon />
              Questions post capture
            </Box>
          </LoadingButton>
          <LoadingButton
            onClick={() => navigate(`/hints/show?cryptorId=${cryptorId}`)}
          >
            <Box display="flex" gap={0.5}>
              <PersonSearchIcon />
              Indices
            </Box>
          </LoadingButton>
          <Divider orientation="vertical" flexItem />
          {defaultButtons}
          <SelectLanguage />
        </Box>
      )}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <NumberField value={record?.id ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Nom
        </Typography>
        <TextField value={record?.name} />
        <Typography variant="body1" fontWeight="bold">
          Date de naissance
        </Typography>
        <DateField value={record?.birthDate} />
        <Typography variant="body1" fontWeight="bold">
          Date de décès
        </Typography>
        <DateField value={record?.deathDate} />
        <Typography variant="body1" fontWeight="bold">
          Place of birth
        </Typography>
        <TextField value={record?.placeOfBirth} />
        <Typography variant="body1" fontWeight="bold">
          Titre
        </Typography>
        <TextField
          value={getTranslation(record?.titleTranslation, i18n.language)?.value}
        />
        <Typography variant="body1" fontWeight="bold">
          Lien avec le lieu
        </Typography>
        <TextField
          value={
            getTranslation(record?.linkWithThePlaceTranslation, i18n.language)
              ?.value
          }
        />
        <Typography variant="body1" fontWeight="bold">
          Quote
        </Typography>
        <TextField
          value={getTranslation(record?.quoteTranslation, i18n.language)?.value}
        />
        <Typography variant="body1" fontWeight="bold">
          Description
        </Typography>
        <TextField
          value={
            getTranslation(record?.descriptionTranslation, i18n.language)?.value
          }
        />
        <Typography variant="body1" fontWeight="bold">
          Difficulté
        </Typography>
        <TextField
          value={
            getTranslation(record?.difficultyTranslation, i18n.language)?.value
          }
        />

        <Box display="flex" flexDirection="row" gap={3}>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Image
            </Typography>
            <img src={record?.image?.url} alt="Cryptor" width="100" />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Image (dissimulée)
            </Typography>
            <img src={record?.obfuscatedImage?.url} alt="Cryptor" width="100" />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Avatar
            </Typography>
            <img src={record?.avatar.image?.url} alt="Cryptor" width="100" />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Image du personnage
            </Typography>
            <img src={record?.characterImage.url} alt="Cryptor" width="100" />
          </Box>
        </Box>

        <Typography variant="body1" fontWeight="bold">
          Rayon de localisation (en mètres)
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Récompense
        </Typography>
        <NumberField value={record?.reward ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Couleur primaire
        </Typography>
        <TextField value={record?.primaryColor} />
        <Typography variant="body1" fontWeight="bold">
          Couleur secondaire
        </Typography>
        <TextField value={record?.secondaryColor} />

        <CryptorArtefactShow
          title="Artefact (lien avec le lieu)"
          artefact={record?.artefactLinkWithThePlace}
        />
        <CryptorArtefactShow
          title="Artefact (description)"
          artefact={record?.artefactDescription}
        />
        <CryptorArtefactShow
          title="Artefact (citation)"
          artefact={record?.artefactQuote}
        />

        <CryptorAddresses data={record?.addresses} />

        <Typography variant="body1" fontWeight="bold">
          Date de création
        </Typography>
        <DateField value={record?.createdAt} />
        <Typography variant="body1" fontWeight="bold">
          Description IA
        </Typography>
        <TextField value={record?.descriptionForAI} />
        <Typography variant="body1" fontWeight="bold">
          Agent Mistral ID FR
        </Typography>
        <TextField value={record?.mistralAIAgentIdFR} />
        <Typography variant="body1" fontWeight="bold">
          Agent Mistral ID EN
        </Typography>
        <TextField value={record?.mistralAIAgentIdEN} />
        <Typography variant="body1" fontWeight="bold">
          Agent Mistral ID ES
        </Typography>
        <TextField value={record?.mistralAIAgentIdES} />
        <Typography variant="body1" fontWeight="bold">
          Animer les yeux
        </Typography>
        <BooleanField value={record?.shouldAnimateEyes} />
      </Stack>
    </Show>
  );
};
