import React, { useMemo } from "react";
import {
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { useGetAvatars } from "../../api/avatar.api";

export const AvatarList: React.FC<IResourceComponentsProps> = () => {

  const { data: avatars } = useGetAvatars();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 50,
      },
      {
        field: "image.url",
        headerName: "Image",
        flex: 1,
        minWidth: 50,
        valueGetter: (params) => params.row?.image?.url,
        renderCell: function render({ value }) {
          return <img src={value} alt="Avatar" width="50" />;
        },
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        minWidth: 50,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid rows={avatars} columns={columns} autoHeight />
    </List>
  );
};
